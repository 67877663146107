var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-alert__table editable_table flex flex-col w-full"},[_c('v-data-table',{ref:"filterTable",staticClass:"a-table w-full",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataArr,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"footer-props":_vm.footerProps,"item-class":_vm.isNotRelevantClass,"data-cy":"alertList","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"no-data-text":_vm.emptyText,"no-results-text":_vm.emptyText},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.relevant",fn:function(ref){
var item = ref.item;
return [_c('CheckboxCell',{ref:("relevant-" + (item.alertId)),attrs:{"edit-active":item.active,"cell-id":item.alertId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.relevant),callback:function ($$v) {_vm.$set(item, "relevant", $$v)},expression:"item.relevant"}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("date-" + (item.alertId)),attrs:{"edit-active":item.active,"label":"Date","cell-id":item.alertId,"disabled":_vm.disabled},on:{"edit-row":_vm.toggleEdit},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('DatetimePickerCell',{ref:("time-" + (item.alertId)),attrs:{"edit-active":item.active,"cell-id":item.alertId,"disabled":_vm.disabled,"label":"00:00 AM","isRequired":true,"type":"time","format":_vm.timeFormat,"rules":[_vm.required]},on:{"edit-row":_vm.toggleEdit},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(!_vm.isDashboard)?_c('SelectCell',{ref:("type-" + (item.alertId)),attrs:{"edit-active":item.active,"label":"Type","items":_vm.types,"isRequired":true,"cell-id":item.alertId,"disabled":_vm.disabled,"displayValue":_vm.getDisplayValue(item.type, _vm.types, 'text')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}}):_c('div',[_vm._v(" "+_vm._s(_vm.getDisplayValue(item.type, _vm.types, 'text'))+" ")])]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [(!_vm.isDashboard)?_c('SelectCell',{ref:("level-" + (item.alertId)),attrs:{"edit-active":item.active,"label":"Level","items":_vm.levels,"isRequired":true,"cell-id":item.alertId,"disabled":_vm.disabled,"displayValue":_vm.getDisplayValue(item.level, _vm.levels, 'text')},on:{"edit-row":_vm.toggleEdit},model:{value:(item.level),callback:function ($$v) {_vm.$set(item, "level", $$v)},expression:"item.level"}}):_c('div',[_c('fa-icon',{staticClass:"mr-1 text-xs",class:_vm.levelClass(item.level),attrs:{"icon":['fas', 'circle']}}),_c('span',{staticClass:"text-xs font-bold uppercase",class:_vm.levelClass(item.level)},[_vm._v(" "+_vm._s(_vm.getDisplayValue(item.level, _vm.levels, 'text'))+" ")])],1)]}},{key:"item.desc",fn:function(ref){
var item = ref.item;
return [_c('EditableCell',{ref:("desc-" + (item.alertId)),attrs:{"edit-active":item.active,"cell-id":item.alertId,"disabled":_vm.disabled,"label":"Description"},on:{"edit-row":_vm.toggleEdit},model:{value:(item.desc),callback:function ($$v) {_vm.$set(item, "desc", $$v)},expression:"item.desc"}})]}},(!_vm.disabled)?{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [(!_vm.isDashboard)?_c('div',{staticClass:"editable_table__edit-record ml-2 absolute text-center",class:{ 'active-tr': item.active}},[(!item.active && _vm.isDeletable)?_c('a',{staticClass:"mx-1 bg-red-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-red",attrs:{"icon":"trash-alt","data-cy":"deleteRecord"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"mr-1 bg-orange-light",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('fa-icon',{staticClass:"text-orange",attrs:{"icon":"times"}})],1):_vm._e(),(item.active)?_c('a',{staticClass:"hover:text-primary bg-primary-lightest",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler(item)}}},[_c('fa-icon',{staticClass:"text-primary",attrs:{"icon":"check"}})],1):_vm._e()]):_vm._e(),(_vm.isDashboard)?_c('div',{staticClass:"text-right tablet-hide",class:{ 'active-tr': item.active}},[_c('a',{staticClass:"hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.rowClickHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-2 ml-3",attrs:{"icon":"chevron-right"}})],1)]):_vm._e()]}}:null,(!_vm.disabled)?{key:"footer",fn:function(){return [(!_vm.isDashboard)?_c('v-btn',{staticClass:"mt-2 v-btn--flat inline",attrs:{"depressed":"","color":"success"},on:{"click":_vm.addAlert}},[_c('fa-icon',{staticClass:"mr-1 group-hover:text-primary-light",attrs:{"icon":"plus"}}),_vm._v(" Add another ")],1):_vm._e()]},proxy:true}:null,{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.showClientName(item.episode))+" ")]}},{key:"item.episode",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"truncate w-130"},[_vm._v(" "+_vm._s(_vm.getSiteSection(item.episode))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }