<template>
    <div class="c-alert__table editable_table flex flex-col w-full">
        <v-data-table
            ref="filterTable"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataArr"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :footer-props="footerProps"
            :item-class="isNotRelevantClass"
            class="a-table w-full"
            data-cy="alertList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :no-data-text="emptyText"
            :no-results-text="emptyText"
            @click:row="rowClickHandler"
        >
            <template v-slot:item.relevant="{ item }">
                <CheckboxCell
                    :ref="`relevant-${item.alertId}`"
                    :edit-active="item.active"
                    :cell-id="item.alertId"
                    :disabled="disabled"
                    v-model="item.relevant"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.date="{ item }">
                <DatePickerCell
                    :ref="`date-${item.alertId}`"
                    :edit-active="item.active"
                    label="Date"
                    v-model="item.date"
                    :cell-id="item.alertId"
                    :disabled="disabled"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.time="{ item }">
                <DatetimePickerCell
                    :ref="`time-${item.alertId}`"
                    :edit-active="item.active"
                    :cell-id="item.alertId"
                    v-model="item.time"
                    :disabled="disabled"
                    label="00:00 AM"
                    :isRequired="true"
                    type="time"
                    :format="timeFormat"
                    :rules="[required]"
                    @edit-row="toggleEdit"
                />
            </template>
            <template class="" v-slot:item.type="{ item }">
                <SelectCell
                    v-if="!isDashboard"
                    :ref="`type-${item.alertId}`"
                    :edit-active="item.active"
                    label="Type"
                    v-model="item.type"
                    :items="types"
                    :isRequired="true"
                    :cell-id="item.alertId"
                    :disabled="disabled"
                    :displayValue="getDisplayValue(item.type, types, 'text')"
                    @edit-row="toggleEdit"
                />
                <div v-else>
                    {{ getDisplayValue(item.type, types, 'text') }}
                </div>
            </template>
            <template class="" v-slot:item.level="{ item }">
                <SelectCell
                    v-if="!isDashboard"
                    :ref="`level-${item.alertId}`"
                    :edit-active="item.active"
                    label="Level"
                    v-model="item.level"
                    :items="levels"
                    :isRequired="true"
                    :cell-id="item.alertId"
                    :disabled="disabled"
                    :displayValue="getDisplayValue(item.level, levels, 'text')"
                    @edit-row="toggleEdit"
                />
                <div v-else>
                    <fa-icon :icon="['fas', 'circle']" class="mr-1 text-xs" :class="levelClass(item.level)" />
                    <span class="text-xs font-bold uppercase" :class="levelClass(item.level)">
                        {{ getDisplayValue(item.level, levels, 'text') }}
                    </span>
                </div>
            </template>
            <template class="" v-slot:item.desc="{ item }">
               <EditableCell
                   :ref="`desc-${item.alertId}`"
                   :edit-active="item.active"
                   :cell-id="item.alertId"
                   v-model="item.desc"
                   :disabled="disabled"
                   label="Description"
                   @edit-row="toggleEdit"
               />
            </template>
            <!-- TODO: when upgrading, need to change below to this: [`item.editRecord`] -->
            <template class="" v-slot:item.editRecord="{ item }" v-if="!disabled">
                <div class="editable_table__edit-record ml-2 absolute text-center" :class="{ 'active-tr': item.active}" v-if="!isDashboard">
                    <a
                        href="#"
                        v-if="!item.active && isDeletable"
                        @click.prevent="deleteHandler(item)"
                        class="mx-1 bg-red-light"
                    >
                        <fa-icon icon="trash-alt" class="text-red" data-cy="deleteRecord" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="cancelHandler"
                        class="mr-1 bg-orange-light"
                    >
                        <fa-icon icon="times" class="text-orange" />
                    </a>
                    <a
                        href="#"
                        v-if="item.active"
                        @click.prevent="saveHandler(item)"
                        class="hover:text-primary bg-primary-lightest"
                    >
                        <fa-icon icon="check" class="text-primary" />
                    </a>
                </div>
                <div class="text-right tablet-hide" :class="{ 'active-tr': item.active}" v-if="isDashboard">
                    <a
                        href="#"
                        @click.prevent="rowClickHandler(item)"
                        class="hover:text-primary mx-2"
                    >
                        <fa-icon icon="chevron-right" class="text-grey-2 ml-3" />
                    </a>
                </div>
            </template>
            <template v-slot:footer v-if="!disabled">
                <v-btn
                    v-if="!isDashboard"
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="success"
                    @click="addAlert"
                >
                    <fa-icon icon="plus" class="mr-1 group-hover:text-primary-light" /> Add another
                </v-btn>
            </template>
            <template class="" v-slot:item.name="{ item }">
                {{ showClientName(item.episode) }}
            </template>
            <template class="" v-slot:item.episode="{ item }">
                <div class="truncate w-130">
                    {{ getSiteSection(item.episode) }}
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { FormEvents, FormRules } from '@/components/shared/mixins/formMixins'
import { EpisodeHelpers } from '@/components/shared/mixins/episodeMixins'
import { ClientHelpers } from '@/components/shared/mixins/clientMixins'
import CheckboxCell from '@/components/partials/CheckboxCell'
import EditableCell from '@/components/partials/EditableCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import DatetimePickerCell from '@/components/partials/DatetimePickerCell'
import SelectCell from '@/components/partials/SelectCell'
import INSERT_ALERT from '@/graphql/mutations/insertAlert.gql'
import DELETE_ALERT from '@/graphql/mutations/deleteAlert.gql'
import snakecaseKeys from 'snakecase-keys'
import camelcaseKeys from 'camelcase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import { format } from 'date-fns'
import { SharedMixin } from '@/helpers/displayValue'

export default {
    name: 'AlertTable',
    components: { CheckboxCell, DatePickerCell, DatetimePickerCell, SelectCell, EditableCell },
    mixins: [ FormRules, FormEvents, MessageDialog, EpisodeHelpers, ClientHelpers, SharedMixin ],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        episode: {
            type: Object,
            default: () => {}
        },
        headers: {
            type: Array,
            default: () => []
        },
        source: {
            type: String,
            default: ''
        },
        itemsPerPage: {
            type: Number,
            default: 10
        },
        isEditable: {
            type: Boolean,
            default: true
        },
        isDeletable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            emptyText: 'No active alerts found',
            duration: 3000,
            type: '',
            message: '',
            page: 1,
            tableDataArr: [],
            currentNewId: null,
            activeRowId: null,
            currentRow: null,
            clientId:'',
            footerProps: {
                'items-per-page-options': [5, 10, 20, 30, 40]
            },
            sortBy: [],
            sortDesc: [true],
            refsToCheck: [
                'relevant',
                'date',
                'time',
                'type',
                'level',
                'desc',
            ],
            defaultAlert: {
                date: format(new Date(), 'yyyy-MM-dd'),
                time: null,
                type: null,
                level: null,
                desc: null,
                relevant: true
            },
            timeFormat: 'h:mm a'
        }
    },
    mounted() {
        this.tableDataArr = [...this.tableData]
    },
    methods: {
        isNotRelevantClass(item) {
            return item.relevant ? '' : 'no-longer-relevant'
        },
        rowClickHandler(obj, e) {
            this.currentRow = obj
            if(this.isDashboard) {
                this.clientId = obj.episode.client.clientId
                this.$router.push({
                    name: 'episodes',
                    params: {clientId: this.clientId},
                }).catch(err => err)
            }
            e.select(true)
        },
        levelClass(level) {
            switch(level) {
                case 'HIGH_ALERT':
                    return 'text-red'
                case 'MONITORING':
                    return 'text-orange'
                case 'LOW':
                    return 'text-yellow'
            }
        },
        addAlert() {
            // if we already have a new behaviour Incident, then we stop the function
            if (this.currentNewId) return
            console.log('adding new alert row')

            this.currentNewId = Math.floor(Math.random() * 1000000) + 5000000
            this.activeRowId = this.currentNewId
            const tempAlert = {
                alertId: this.currentNewId,
                episodeId: this.episode?.episodeId,
                userId: this.loggedInUser?.userId,
                ...this.defaultAlert,
                time: format(new Date(), this.timeFormat)
            }
            this.tableDataArr.push(tempAlert)

            this.page = parseInt((this.tableDataArr.length - 1) / this.itemsPerPage) + 1
        },
        toggleEdit(val) {
            if (
                this.activeRowId && Number(this.activeRowId) === Number(val) ||
                this.currentNewId && Number(this.currentNewId) === Number(val) ||
                !this.isEditable
            ) return

            if (this.currentNewId) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        isActiveRowChanged() {
            // const fields = ['order', 'goal', 'commenced', 'completed']
            const fields = ['goal', 'commenced', 'completed']
            const changedObj = this.getActiveRow(this.tableDataArr)
            if (!changedObj) return false

            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.alertId === this.activeRowId
            })
            return {...activeRow}
        },
        validateInputs(id) {
            const forms = this.refsToCheck.reduce((arr, curr) => {
                arr.push(this.$refs[`${curr}-${id}`].validate())
                return arr
            }, [])
            return Promise.all(forms)
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    // this.mutationCall(this.getActiveRow(this.tableDataArr), false)

                    const data = this.getActiveRow(this.tableDataArr)
                    delete data.updated
                    delete data.created
                    const idx = this.tableDataArr.findIndex(t => t.alertId === data.alertId)

                    if (data && data.typename) delete data.typename
                    if (this.currentNewId && this.currentNewId === this.activeRowId) delete data.alertId

                    delete data.episode
                    delete data.active
                    const convertedData = snakecaseKeys(data, {deep: true})
                    this.$apollo.mutate({
                        mutation: INSERT_ALERT,
                        variables: {
                            alert: convertedData
                        }
                    }).then(({ data: { alert } }) => {
                        console.log('alert returned', alert)
                        if (alert) {
                            this.tableDataArr[idx] = {...camelcaseKeys(alert, {deep: true})}
                        }

                        this.saving = false
                        this.message = 'Saved alert item'
                        this.type = 'success'

                        this.activeRowId = null
                        this.currentNewId = null
                        this.activeRowObj = null

                        this.$emit('alert-mutated')
                    }).catch(error => {
                        this.message = 'Failed saving alert'
                        this.type = 'error'
                    }).finally(() => {
                        this.saving = false
                        data.active = true
                        this.showMessage({ duration: 5000 })
                    })
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.alertId === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.currentNewId) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.alertId === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.alertId !== this.activeRowId) return item
                })
            }
        },
        deleteHandler(item) {
            this.$apollo.mutate({
                mutation: DELETE_ALERT,
                variables: {
                    alertId: item.alertId
                }
            }).then(({ data: { alert } }) => {
                console.log('alert returned', alert)

                this.clearRowSelection()
                this.tableDataArr = this.tableDataArr.filter(td => {
                    if (td.alertId !== item.alertId) return td
                })

                this.saving = false
                this.message = 'Deleted alert'
                this.type = 'success'

                this.activeRowId = null
                this.currentNewId = null
                this.activeRowObj = null

                this.$emit('alert-mutated')
            }).catch(error => {
                this.message = 'Failed deleting alert'
                this.type = 'error'
            }).finally(() => {
                this.saving = false
                this.showMessage({ duration: 5000 })
            })
        },
        cancelHandler() {
            this.resetTableRow()
            this.clearRowSelection()
        },
        clearRowSelection() {
            this.activeRowId = null
            this.currentNewId = null
            this.activeRowObj = null
        },
        showClientName(episode) {
            return this.getFullName(episode?.client)
        }
    },
    computed: {
        ...mapState({
            loggedInUser: state => state.app.loggedInUser,
            staticChoices: state => state.app.staticChoices,
            siteSections: state => state.app.siteSections
        }),

        types() {
            return this.staticChoices['alert.types']
        },
        levels() {
            return this.staticChoices['alert.levels']
        },
        isDashboard() {
            return this.source === 'dashboard'
        }
    },
    watch: {
        tableData() {
            this.clearRowSelection()
            this.tableDataArr = [...this.tableData]
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        }
    }
}
</script>
