<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                dense single-line hide-details
                :ripple="false"
                @click="cellClickHandler"
                :is="renderEditableComponent"
                @focus="onCellClick"
                v-model="formInput.inputValue"
                class="p-0 block w-4"
                :class="{'active': formInput.inputValue}"
                :rules="isRequired ? rules : []"
            >
                <slot></slot>
            </component>
        </component>
<!--        <a-->
<!--            @click="cellClickHandler"-->
<!--            v-show="!editActive"-->
<!--            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"-->
<!--            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue && !enforceValid}"-->
<!--        >-->
<!--            <fa-icon :icon="iconName" class="text-center"></fa-icon>-->
<!--        </a>-->
    </div>
</template>

<script>
export default {
    name: "CheckboxCell",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        cellId: {
            type: [Number, String],
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-simple-checkbox'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: Boolean,
            default: null
        },
        enforceValid: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isValid: true,
            focused: false,
            formInput: {inputValue: null},
        }
    },
    mounted() {
        console.log('value', this.value)
        this.formInput.inputValue = this.value == null ? this.defaultValue : this.value
        console.log('form input value', this.formInput.inputValue)
    },
    methods: {
        cellClickHandler() {
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate() {
            this.isValid = this.$refs.formInput.validate()
            return this.isValid
        },
        clearValidate(){
            this.isValid = true
            return this.isValid
        },
    },
    computed: {
        linkText() {
            let text = this.value ? 'Verified' : ''
            return text || ''
        },
        iconName() {
            console.log('value passed in', this.value)
            return this.value ? 'check-square' : ['far', 'square']
        },
        renderEditableComponent() {
            // if (this.editActive) return this.editableComponent
            // return ''
            return this.editableComponent
        },
        renderForm() {
            // if (this.editActive) return 'v-form'
            return 'v-form'
        },
    },
    watch: {
        value() {
            console.log('formInput value', this.formInput)
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            console.log('formInput value', this.formInput)
            this.$emit('input', this.formInput.inputValue)
        },
    },
}
</script>
